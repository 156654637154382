import React from "react"
import Layout from "../components/layout"
// import "react-responsive-carousel/lib/styles/carousel.min.css"
import TechnicalData from "../components/product_tech_data"
import ProductDetail from "../components/product_detail"
import mobile_toilet from "../../public/static/images/products/mobile_toilet.jpg"

function MobileToilet() {

  const prodDetail = {
    "name": "MOBILE TOILET BIO",
    "detail": {
      "about": ["Eco-Friendly Mobile Toilet Van. The offered toilet finds wide application use in the trade fair. Furthermore, the toilet van is manufactured using quality material and approved technologies. In addition, the toilet van is made available to the clients at marginal rates."],
      "advantages" : ["Enhanced Durability", "Fine finish", "Outdoor Event", "Public Area", "Construction Area"],
      "Features": [
        "Enhanced durability",
        "Fine finish"
      ],
      "Usage": [
        "Outdoor Event",
        "Public Area",
        "Construction Area"
      ]
    },
    "tech_data": {
      "column1": [
        "Code",
        "Seater",
        "Sheet Material",
        "Thickness",
        "Sludge Tank Capacity",
        "Water Capacity",
        "Over all size",
        "Cabin Size",
        "Arrangement",
        "Facilities"
      ],
      "column2": [
        "IEMT / IEMU",
        "4, 6, 8, 10 Seat or Customized",
        "M.S. Galvanized Iron, Stainless Steel, FRP Etc.",
        "1 mm, 1.6 mm, 2 mm, 2.5 mm, 3 mm or can be Customized as per requirement.",
        "750 ltres. or can be Customized as per requirement.",
        "500 ltres. or can be Customized as per requirement.",
        "19.5x8x10 feet or Depend on Requirement",
        "915 x 900 x 1830 mm or can be Customized as per requirement.",
        "Provision for Both Men & Women with fully furnished Public service at fairs.",
        "Proper Ventilation, Exhaust Fans, Superior Finishing with facilities like Mirror, Soap, Tray Towel, Rod, Washbasin, Handle with Locks etc."
      ]
    }
  }

  return (
    <Layout className="semi-automatic">
      <ProductDetail name={prodDetail.name} detail={prodDetail.detail} image={mobile_toilet} />

      {prodDetail.tech_data !== undefined ? <TechnicalData techData={prodDetail.tech_data} /> : <></>}
    </Layout>
  )
}

export default MobileToilet
